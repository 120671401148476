import { configureStore } from "@reduxjs/toolkit";

import initAssessmentReducer from "./slice/initAssessmentSlice";
import assessmentMasterReducer from "./slice/assessmentMasterSlice";
import assessmentResultReducer from "./slice/assessmentResultSlice";


export const store = configureStore({
    reducer: {
        initAssessment: initAssessmentReducer,
        assessmentMaster: assessmentMasterReducer,
        assessmentResult: assessmentResultReducer,
    },
})


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch