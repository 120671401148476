import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../redux/store';
import { initAssessmentThunk } from '../../redux/slice/initAssessmentSlice';
import ErrorIcon from '@mui/icons-material/Error';
import { VERIFY_OTP } from '../../routes/routes';
import SecureStorage from '../../util/SecureStorage';
import { LSK_UID } from '../../constants/local-storage-constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const InitAssessment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const uid = searchParams.get('uid');

    const { isLoading, errorMessage } = useSelector((state: RootState) => state.initAssessment)

    const dispatch: AppDispatch = useDispatch()

    const verifyToken = () => {
        const params = {uid: uid};
        dispatch(initAssessmentThunk(params))
        .unwrap()
        .then((res) => {
            navigate(VERIFY_OTP, { 
                replace: true,
            });
        })
        .catch((e) => null);
    }

    useEffect(() => {
        verifyToken();

        if(uid)
            SecureStorage.setItem(LSK_UID, uid.toString())
    }, []);

  return (
    <div className="vh-100 bg-light">
        <div className="row justify-content-center g-0 p-0 py-lg-4 m-0 h-100">
            <div className="col-lg-6 rounded bg-white h-100">
                <div className="d-flex gap-2 justify-content-center align-items-center flex-column h-100">
                    <>
                        {
                            isLoading
                                ? <CircularProgress />
                                : (
                                    errorMessage !== ''
                                    ? <ErrorIcon sx={{ fontSize: '50px', color: 'orange' }} />
                                    : <CheckCircleIcon sx={{ fontSize: '50px', color: 'green' }} />
                                )
                        }
                    </>
                    <span className="fs-sm-lg-med text-grey">
                        {
                            !isLoading && errorMessage !== ''
                                ? errorMessage
                                : ''
                        }
                    </span>
                </div>
            </div>
        </div>
    </div>
  );
}

export default InitAssessment;