import AxiosHelper from "./AxiosHelper"

const login = (body: any) => {

    body.clientIdentifier = 'INVENTORY_WEB'

    return new Promise((resolve, reject) => {
        AxiosHelper
            .httpPost({ path: 'login', queryParams: null, body: body })
            .then((res: any) => {
                return !res.isSuccess ? resolve(true) : reject(res.description)
            })
            .catch(e => reject(e))
    })
}

const apiInitAssessment = (reqParams: any) => {
    return new Promise((resolve, reject) => {
        AxiosHelper
            .httpGet({ path: 'asmt/init-assessment', queryParams: reqParams })
            .then((res: any) => {
                return res.isSuccess ? resolve(res) : reject(res.description)
            })
            .catch(e => reject(e))
    })
}

const apiVerifyOtp = (reqBody: any) => {
    return new Promise((resolve, reject) => {
        AxiosHelper
            .httpPost({ path: 'asmt/verify-otp', body: reqBody })
            .then((res: any) => {
                return res.isSuccess ? resolve(res) : reject(res.description)
            })
            .catch(e => reject(e))
    })
}

const AuthService = {
    login,
    apiInitAssessment,
    apiVerifyOtp,
}

export default AuthService