import AxiosHelper from "./AxiosHelper"

const getBuList = () => {
    return new Promise((resolve, reject) => {
        AxiosHelper.httpGet({ path: 'group/list-group-feature-master/224', queryParams: null })
            .then((res: any) => {
                if (res.isSuccess) {
                    resolve(res.data ? res : false)
                }
                else reject(res.description)
            }).catch(e => reject(e))
    })
}

const apiStartAssessment = (reqBody: any) => {
    return new Promise((resolve, reject) => {
        AxiosHelper.httpPost({ path: 'asmt/start-assessment', body: reqBody })
            .then((res: any) => {
                if (res.isSuccess) {
                    resolve(res.data ? res : false)
                }
                else reject(res.description)
            }).catch(e => reject(e))
    })
}

const apiSubmitAssessment = (reqBody: any) => {
    return new Promise((resolve, reject) => {
        AxiosHelper.httpPost({ path: 'asmt/submit-assessment', body: reqBody })
            .then((res: any) => {
                if (res.isSuccess) {
                    resolve(res.data ? res : [])
                }
                else reject(res.description)
            }).catch(e => reject(e))
    })
}

const NetworkService = {
    getBuList,
    apiStartAssessment,
    apiSubmitAssessment,
}

export default NetworkService