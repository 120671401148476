import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Radio, RadioGroup, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState, store } from '../redux/store';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { categoryStepNext, setAssessmentData, updateChoice, updateTimeCount } from '../redux/slice/assessmentMasterSlice';
import { CHECKBOX, RADIO } from '../constants/api-constants';
import LOGO from '../assets/styra_logo.png';
import TimerIcon from '@mui/icons-material/Timer';
import SecureStorage from '../util/SecureStorage';
import { LSK_ASSESSMENT_DATA, LSK_OTP, LSK_UID } from '../constants/local-storage-constants';
import { submitAssessmentThunk } from '../redux/slice/assessmentResultSlice';
import { RESULT } from '../routes/routes';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../context/SnackbarContext';

const AssessmentPage = () => {
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const [openConfirmSubmit, setOpenConfirmSubmit] = React.useState(false);
    const [openForceSubmit, setOpenForceSubmit] = React.useState(false);
    const [timmerInterval, setTimmerInterval] = useState<any>(null);

    const { isLoading, errorMessage, assessment, currentCategory, initTimmer } = useSelector((state: RootState) => state.assessmentMaster);
    const { categoryList, allowedTimeSec } = assessment;

    const dispatch: AppDispatch = useDispatch();

    const stepNext = () => {
        dispatch(categoryStepNext());
        handlePageUp();
    }

    const handlePageUp = () => {
        window.scrollTo(0, 0);
    }

    const formatTimer = (valueSec: number) => {
        let t = allowedTimeSec - valueSec;
        let min = Math.floor(t / 60).toString().padStart(2, "0");
        let sec = (t % 60 ? (t % 60).toString() : '00').padStart(2, "0");
        return min + ":" + sec;
    }

    function timeCounter() {
        var _allowedTimeSec: number = store.getState().assessmentMaster.assessment.allowedTimeSec;
        var _initTimmer: number = store.getState().assessmentMaster.initTimmer + 1;

        if(_initTimmer <= _allowedTimeSec){
            dispatch(updateTimeCount(_initTimmer));
        } else {
            setOpenForceSubmit(true);
            clearInterval(timmerInterval);

        }
    }

    useEffect(() => {
        const assessmentData = SecureStorage.getItem(LSK_ASSESSMENT_DATA);
        if(assessmentData){
            dispatch(setAssessmentData(assessmentData))
        }

        setTimmerInterval( setInterval(timeCounter, 1000) );
        return(() => clearInterval(timmerInterval));
    }, []);

    const handleChoiceChange = (catId: number, questionId: number, achId: number) => {
        dispatch(updateChoice({catId, questionId, achId}));
    }

    const handleSubmit = (isForceSubmit: number = 0) => {
        const uid = SecureStorage.getItem(LSK_UID);
        const otp = SecureStorage.getItem(LSK_OTP);
        const params = {
            uid: uid,
            otp: otp,
            isForceSubmit: isForceSubmit,
            categoryList: categoryList,
        };
        dispatch(submitAssessmentThunk(params))
            .unwrap()
            .then((res) => {
                navigate(RESULT, { 
                    replace: true,
                });
            })
            .catch((e) => showSnackbar(errorMessage));
    }

  return (
    <div className="bg-light" style={{minHeight: '100vh'}}>
        <Dialog
            open={openForceSubmit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Your time is up!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Please click on submit to get the score.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleSubmit(1)} autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openConfirmSubmit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Do you want to submit?"}
            </DialogTitle>
            <DialogActions>
                <Button sx={{textTransform: 'none'}} onClick={() => setOpenConfirmSubmit(false)}>
                    No
                </Button>
                <Button sx={{textTransform: 'none'}} variant="contained" disableElevation onClick={() => handleSubmit(0)} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

        <div className="custom-navbar bg-white py-3">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <img src={LOGO} alt='...' style={{height: '50px'}} />

                      <div className="d-flex align-items-center timer-container py-2 px-3" style={{width: '120px'}}>
                          <TimerIcon />
                        <span className="fs-med-lg-med mx-2">{formatTimer(initTimmer)}</span>
                      </div>
                </div>
            </div>
        </div>

        <div className="row justify-content-center g-0 p-0 py-lg-4 m-0">
            <div className="col-lg-6 p-3 p-md-0">
                <div className="d-flex gap-3 flex-column">
                    <div className="rounded bg-white p-4 cateory-header">
                        <span className="fs-lg-semibold">
                            {categoryList[currentCategory]?.catDescription}
                        </span>
                    </div>

                    {
                        categoryList[currentCategory]?.questionList.map((question, questionIndex) => (
                            <div className="d-flex gap-2 flex-column rounded bg-white p-3 question-card" key={question.questionId}>
                                {
                                    question.imageURL
                                    ? <img src={question?.imageURL} alt='...' />
                                    : ''
                                }

                                <span className="fs-sm-lg-med">{questionIndex + 1}. {question.question}</span>

                                <div>
                                    {
                                        question.componentTypeId === CHECKBOX
                                        ? <FormGroup>
                                            {
                                                question?.choiceList?.map((choice, choiceIndex) => (
                                                    <FormControlLabel control={<Checkbox checked={!!choice.isSelected} onChange={() => handleChoiceChange(
                                                        categoryList[currentCategory]?.catId,
                                                        question.questionId,
                                                        choice.achId
                                                    )} />} label={choice?.optionAnswers} key={choice.achId} />
                                                ))
                                            }
                                        </FormGroup>
                                        : question.componentTypeId === RADIO
                                        ? <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                        >
                                            {
                                                question?.choiceList?.map((choice, choiceIndex) => (
                                                    <FormControlLabel control={<Radio checked={!!choice.isSelected} />} label={choice?.optionAnswers} key={choice.achId} 
                                                    onChange={() => handleChoiceChange(
                                                        categoryList[currentCategory]?.catId,
                                                        question.questionId,
                                                        choice.achId
                                                    )} />
                                                ))
                                            }
                                        </RadioGroup>
                                        : <></>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

        <div className="custom-footer bg-white py-2">
            <div className="container">
                <div className="d-flex justify-content-end align-items-center py-1">
                    {
                        (currentCategory + 1) >= categoryList.length
                        ? <Button 
                            sx={{textTransform: 'none'}}
                            variant="contained"
                            disableElevation
                            onClick={() => setOpenConfirmSubmit(true)}
                        >
                            <span>Submit</span>
                        </Button>
                        : <div className="d-flex gap-2 align-items-center custom-link" onClick={stepNext}>
                        <span className="fs-med-lg-med">{categoryList[currentCategory + 1]?.catDescription}</span>
                        <ArrowCircleRightOutlinedIcon color="primary" />
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
  );
}

export default AssessmentPage;