export const LOGIN = '/login'
export const OTP = '/otp'
export const DASHBOARD = '/'
export const USER = '/user'
export const CONTACT = '/contact'

export const INIT_ASSESSMENT = '/init-assessment'
export const VERIFY_OTP = '/verify-otp'

export const ASSESSMENT = '/assessment'
export const RESULT = '/result'
