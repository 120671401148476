import React, { lazy } from 'react'
import AuthLayout from '../Layout/AuthLayout';
import MainLayout from '../Layout/MainLayout';
import OpenLayout from '../Layout/OpenLayout';
import { ASSESSMENT, INIT_ASSESSMENT, RESULT, VERIFY_OTP } from './routes';
import OtpVerification from '../pages/auth/otp-verification';
import InitAssessment from '../pages/auth/init-assessment';
import AssessmentPage from '../pages/assessment';
import ResultPage from '../pages/result';

const RouteBuilder = (isLoggedIn: boolean) => {

  const NotFound = lazy(() => import("../pages/not-found"))
 
  return [
    {
      element: <AuthLayout />,
      children:
        [
          { path: INIT_ASSESSMENT, element: <InitAssessment /> },
          { path: VERIFY_OTP, element: <OtpVerification /> },
        ],
    },
    {
      element: <OpenLayout />,
      children:
        [
          { path: ASSESSMENT, element: <AssessmentPage /> },
          { path: RESULT, element: <ResultPage /> },
          { path: "*", element: <NotFound /> },
        ],
    },
  ]
}

export default RouteBuilder