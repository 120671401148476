import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles/style.scss";
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AxiosInterceptor from './network/AxiosInterceptor';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  // <React.StrictMode>
    <UserProvider>
      <AxiosInterceptor>
        <Provider store={store} >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </AxiosInterceptor>
    </UserProvider>
  // </React.StrictMode>
);
