import React from 'react';
import LOGO from '../assets/styra_logo.png';
import CircularProgressWithLabel from '../component/CircularProgressWithLabel';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';

const ResultPage = () => {
    const { userData } = useSelector((state: RootState) => state.initAssessment);
    const { assessmentResult } = useSelector((state: RootState) => state.assessmentResult);

    const formatTimer = (valueSec: number) => {
        let t = valueSec;
        let min = Math.floor(t / 60).toString().padStart(2, "0");
        let sec = (t % 60 ? (t % 60).toString() : '00').padStart(2, "0");
        return min + ":" + sec;
    }

  return (
    <div className="custom-navbar bg-light" style={{minHeight: '100vh'}}>
        <div className="bg-white py-3">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <img src={LOGO} alt='...' style={{height: '50px'}} />
                </div>
            </div>
        </div>

        <div className="row justify-content-center g-0 p-0 py-lg-4 m-0">
            <div className="col-lg-6 p-3 p-md-0">
                <div className="d-flex gap-3 flex-column">
                    <div className="rounded bg-white p-5 h-100">
                        <div className="d-flex gap-3 flex-column">
                            <span className="fs-lg-semibold text-primary">
                                Final Result
                            </span>

                            <span className="fs-med-lg-semibold text-black text-center">
                                Hi, <span>{userData.fullName}</span>
                            </span>

                            <div className="d-flex gap-2 flex-column rounded bg-light p-3">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <span className="fs-sm-lg-med">Your Score</span>
                                    <span className="fs-lg-semibold">{assessmentResult.securedScore}/{assessmentResult.totalScore}</span>
                                    <span className="fs-normal-semibold mt-2">
                                        <span className="fs-normal-light me-2">Time Taken:</span>
                                        {formatTimer(assessmentResult.timeTakenSecs)}
                                    </span>
                                </div>
                            </div>

                            <span className="fs-sm-lg-semibold text-primary">
                                Category wise score
                            </span>

                              <div className="row g-3 p-0 m-0">
                                  {
                                      assessmentResult?.catScoreList?.map((score, scoreIndex) => (
                                          <div className="col-12" key={scoreIndex}>
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <span className="fs-normal-semibold">{score.catDescription}</span>
                                                    <span className="fs-normal-med">{score.securedScore}/{score.totalScore}</span>
                                                </div>
                                              <LinearProgress variant="determinate" value={(score.securedScore * 100) / score.totalScore } />
                                          </div>
                                      ))
                                  }
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ResultPage;