import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../network/AuthService";

export const initAssessmentThunk = createAsyncThunk(
    '/init-assessment',
    async (params: any, thunkApi) => await AuthService.apiInitAssessment(params)
)

export const verifyOtpThunk = createAsyncThunk(
    '/verify-otp',
    async (body: any, thunkApi) => await AuthService.apiVerifyOtp(body)
)

const initAssessmentSlice = createSlice({
    name: "initAssessment",
    initialState: {
        userData: {
            fullName: '',
        },
        isLoading: true,
        errorMessage: '',
        successMessage: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(initAssessmentThunk.pending, (state, action) => {
                state.isLoading = true
                state.errorMessage = ''
                state.successMessage = ''
            })
            .addCase(initAssessmentThunk.fulfilled, (state, action) => {
                const { data, description } = action.payload as any;

                state.userData = data
                state.isLoading = false
                state.errorMessage = ''
                state.successMessage = ''
            })
            .addCase(initAssessmentThunk.rejected, (state, action) => {
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
                state.successMessage = ''
            })

            .addCase(verifyOtpThunk.pending, (state, action) => {
                state.isLoading = true
                state.errorMessage = ''
                state.successMessage = ''
            })
            .addCase(verifyOtpThunk.fulfilled, (state, action) => {
                const { data, description } = action.payload as any;

                state.isLoading = false
                state.errorMessage = ''
                state.successMessage = description
            })
            .addCase(verifyOtpThunk.rejected, (state, action) => {
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
                state.successMessage = ''
            })
    }
});

export default initAssessmentSlice.reducer;