import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkService from "../../network/NetworkService";

export const submitAssessmentThunk = createAsyncThunk(
    '/submit-assessment',
    async (body: any, thunkApi) => await NetworkService.apiSubmitAssessment(body)
)

interface CategoryScore {
    catId: number;
    catDescription: string;
    totalScore: number;
    securedScore: number;
}

const initialAssessmentResult = {
    totalScore: 0,
    securedScore: 0,
    timeTakenSecs: 0,
    catScoreList: [] as CategoryScore[],
}

const assessmentResultSlice = createSlice({
    name: "assessmentResult",
    initialState: {
        assessmentResult: { ...initialAssessmentResult },
        isLoading: true,
        errorMessage: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitAssessmentThunk.pending, (state, action) => {
                state.isLoading = true
                state.errorMessage = ''
            })
            .addCase(submitAssessmentThunk.fulfilled, (state, action) => {
                const { data } = action.payload as any;

                state.assessmentResult = data
                state.isLoading = false
                state.errorMessage = ''
            })
            .addCase(submitAssessmentThunk.rejected, (state, action) => {
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
            })
    }
});

export default assessmentResultSlice.reducer;