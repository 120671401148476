import { InternalAxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { KEY_X_AUTH_TOKEN, KEY_X_REFRESH_TOKEN, SUCCESS_RESPONSE } from '../constants/api-constants';
import { LSK_REFRESH_TOKEN, LSK_TOKEN } from '../constants/local-storage-constants';
import SecureStorage from '../util/SecureStorage';
import rsAxiosInstance from './AxiosConfig';
import { useUserContext } from '../context/UserContext';
import { ResponseType } from '../model/network';
// import { useSnackbar } from '../context/SnackbarContext';

const AxiosInterceptor = ({ children }: any) => {

    const { setIsLoggedIn, logout } = useUserContext()

    // const { showSnackbar } = useSnackbar()

    // open urls to exclude headers and 401 errors
    const OPEN_URL_LIST = [
        'login',
        'refresh-token'
    ]

    const AUTHENTICATE = 'login'
    const REFRESH_TOKEN = 'refresh-token'

    const handleServerError = (error: any) => {

        if (error && error.response && error.response.data && error.response.data.message) {
            return Error(error.response.data.message)
        }

        if (error.message) {
            return Error(error.message)
        }

        return Error("Unknown Error")
    }

    useEffect(() => {

        let isRefreshing: boolean; // Track whether a token refresh is in progress
        let refreshSubscribers: any[] = [] // Array to hold the pending API requests

        const reqInterceptor = rsAxiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {

            let token = SecureStorage.getItem(LSK_TOKEN)

            // open url list will be excluded
            if (config && config.url) {
                if (!OPEN_URL_LIST.includes(config.url) && token) {
                    config.headers['Authorization'] = `Bearer ${token}`
                }

                if (config.url && !config.headers['Content-Type']) {
                    config.headers['Content-Type'] = 'application/json';
                }
            }

            return config
        }, error => {
            return Promise.reject(error)
        })

        const resInterceptor = rsAxiosInstance.interceptors.response.use(({ config, data, headers }): any => {

            if (!config || !data)
                return Promise.reject(Error("Something went wrong"))

            if (!config.url)
                return Promise.reject(Error("Something went wrong"))

            if (data.status === SUCCESS_RESPONSE) {
                if (config.url === AUTHENTICATE) {
                    SecureStorage.setItem(LSK_TOKEN, headers[KEY_X_AUTH_TOKEN])
                    SecureStorage.setItem(LSK_REFRESH_TOKEN, headers[KEY_X_REFRESH_TOKEN])
                    // NOTE : Incase if custom handling is required then below can be moved to auth screen 
                    setIsLoggedIn(true)
                }

                if (config.url === REFRESH_TOKEN) {
                    SecureStorage.setItem(LSK_TOKEN, headers[KEY_X_AUTH_TOKEN])
                }
            }

            const res = new ResponseType(data)

            // if (!res.isSuccess) {
            //     showSnackbar(res.description) // handled errors will be shown here
            // }

            return Promise.resolve(res)
        }, async (error) => {

            if (!error.response) {
                return Promise.reject(handleServerError(error))
            }

            const originalRequest = error.config

            const reqUrl = error.response.config.url

            if (error.response.status === 401 && !originalRequest._retry && reqUrl !== REFRESH_TOKEN) {

                originalRequest._retry = true

                const retryOriginalRequest = new Promise((resolve) => {
                    refreshSubscribers.push(() => resolve(rsAxiosInstance(originalRequest)))
                })

                if (!isRefreshing) {
                    isRefreshing = true

                    try {

                        const body = {
                            refreshToken: SecureStorage.getItem(LSK_REFRESH_TOKEN)
                        }

                        await rsAxiosInstance
                            .post(
                                REFRESH_TOKEN,
                                body,
                            )

                        refreshSubscribers.forEach((subscriber) => subscriber())
                        refreshSubscribers = []

                    } catch (refreshError: any) {
                        console.log("test");
                        logout()
                    }

                    isRefreshing = false
                }

                return retryOriginalRequest
            }

            // Return any other error response
            return Promise.reject(handleServerError(error))

        })

        return () => {
            rsAxiosInstance.interceptors.response.eject(reqInterceptor)
            rsAxiosInstance.interceptors.response.eject(resInterceptor)
        };
    }, [])


    return children;
}

export default AxiosInterceptor
